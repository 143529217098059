import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsWrapper: {
      display: "flex",
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey[300],
    },
  })
);
