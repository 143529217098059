import { FC, useContext, useEffect, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import DnsIcon from "@material-ui/icons/Dns";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";

import { Header } from "components/Header";
import { Back } from "components/Back";
import { AuthPage } from "pages/Auth";
import { ListPage } from "pages/List";
import { DevicePage } from "pages/Device";
import { LoginContext, useDevices } from "api";
import { NavigationDrawer } from "components/NavigationDrawer";
import { useStoreon } from "storeon/react";
import { StatisticsPage } from "pages/Statistics";
import { DeviceSelect } from "components/DeviceSelect";
import { DaterangeSelector } from "components/DaterangeSelector";
import { MessagesPage } from "pages/Messages";
import { RecipesPage } from "pages/Recipes";
import { EfficiencyPage } from "pages/Efficiency";

export const App: FC = () => {
  const login = useContext(LoginContext);
  const devices = useDevices({
    pageNumber: 0,
    pageSize: 100,
  });
  const { device, dispatch } = useStoreon("device");
  const history = useHistory();

  const drawerRoutes = useMemo(() => {
    return [
      {
        icon: <DnsIcon />,
        label: "Оборудование",
        route: `/devices`,
        routeName: "devices",
      },
      {
        icon: <TrendingUpIcon />,
        label: "Статистика",
        route: `/statistics/${device.selectedDevice}`,
        routeName: "statistics",
      },
      {
        icon: <AddAlertIcon />,
        label: "События",
        route: `/messages/${device.selectedDevice}`,
        routeName: "messages",
      },
      {
        icon: <AssignmentIcon />,
        label: "Сводная эффективность",
        route: `/efficiency/${device.selectedDevice}`,
        routeName: "efficiency",
      },
      {
        icon: <FreeBreakfastIcon />,
        label: "Рецептура",
        route: `/recipes/${device.selectedDevice}`,
        routeName: "recipes",
      },
    ];
  }, [device.selectedDevice]);

  useEffect(() => {
    if (!device.selectedDevice) return;

    const route = drawerRoutes.find((route) =>
      history.location.pathname.includes(route.routeName)
    );

    if (route) {
      history.replace(route.route);
    }
  }, [device.selectedDevice]);

  useEffect(() => {
    if (!device.selectedDevice && devices?.data && devices?.data[0]) {
      dispatch("device/set", {
        id: "selectedDevice",
        value: devices.data[0]?.id,
      });
    }
  }, [history.location.pathname, device.selectedDevice, devices.data]);

  return (
    <>
      <Box bgcolor={"grey.100"} minHeight={"100vh"}>
        <CssBaseline />
        {login?.isAuth && <Header />}
        <Box display="flex" px={"70px"}>
          <Switch>
            {!login?.isAuth ? (
              <AuthPage />
            ) : (
              <>
                <Route
                  path={"*"}
                  component={(props) => (
                    <NavigationDrawer {...props} routes={drawerRoutes} />
                  )}
                ></Route>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box m={2}>
                    <Route
                      path={[
                        "/statistics/:deviceId",
                        "/messages/:deviceId",
                        "/recipes/:deviceId",
                        "/efficiency/:deviceId",
                      ]}
                      component={DeviceSelect}
                    />
                  </Box>
                  <Box m={2}>
                    <Route
                      path={[
                        "/statistics/:deviceId",
                        "/messages/:deviceId",
                        "/recipes/:deviceId",
                        "/efficiency/:deviceId",
                      ]}
                      component={DaterangeSelector}
                    />
                  </Box>
                  <Route
                    path={"/"}
                    exact={true}
                    component={() => <Redirect to="/devices" />}
                  />
                  <Route path={"/devices"} exact={true} component={ListPage} />
                  <Route
                    path={"/statistics/:deviceId"}
                    exact={true}
                    component={StatisticsPage}
                  />
                  <Route
                    path={"/messages/:deviceId"}
                    exact={true}
                    component={MessagesPage}
                  />
                  <Route
                    path={"/efficiency/:deviceId"}
                    exact={true}
                    component={EfficiencyPage}
                  />
                  <Route
                    path={"/recipes/:deviceId"}
                    exact={true}
                    component={RecipesPage}
                  />
                  <Route
                    path={"/device/:id"}
                    exact={true}
                    component={DevicePage}
                  />
                </Box>
              </>
            )}
          </Switch>
        </Box>
      </Box>
      <Back />
    </>
  );
};
