import { Box } from "@material-ui/core";
import { FC, useMemo, useCallback } from "react";
import { useChangeDeviceBlocked, useEditProduct, useDevice } from "api";
import { useStoreon } from "storeon/react";
import { useParams } from "react-router";

import { RecipesPageProducts } from "./Products";
import { Loader } from "components/Loader";

export const RecipesPage: FC = () => {
  const { daterange } = useStoreon("daterange");
  const { device, dispatch } = useStoreon("device");
  const params = useParams<{ deviceId: string }>();
  const editProduct = useEditProduct();

  const selectedDeviceId = useMemo(() => params.deviceId, [params.deviceId]);

  if (!device.selectedDevice) {
    dispatch("device/set", {
      id: "selectedDevice",
      value: selectedDeviceId,
    });
  }

  const { deviceData, deviceMutate, deviceUpdates } = useDevice(
    selectedDeviceId,
    {
      from: daterange.from,
      to: daterange.to,
    }
  );

  const handleProductChange = useCallback((productId, value) => {
    editProduct(selectedDeviceId, productId, value).then(() => {
      deviceMutate();
    });
  }, []);

  if (!deviceData) return <Loader />;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {deviceData.products && (
        <RecipesPageProducts
          data={deviceData.products}
          handleChange={handleProductChange}
        />
      )}
    </Box>
  );
};
