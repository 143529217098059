import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusIcon: {
      borderRadius: "50%",
      width: "8px",
      height: "8px",
      marginRight: "8px",
    },
    offlineStatusIcon: {
      backgroundColor: theme.palette.error.main,
    },
    onlineStatusIcon: {
      backgroundColor: theme.palette.primary.main,
    },
    onlineText: {
      color: theme.palette.primary.main,
    },
    offlineText: {
      color: theme.palette.error.main,
    },
  })
);
