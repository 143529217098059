import { useCallback } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";

export const useEditProduct = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (deviceId: string | number, productId: string | number, value: object) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/device/${deviceId}/product/${productId}`,
          method: "PUT",
          data: value,
        })
          .then((data) => {
            resolve(data);
            enqueueSnackbar("Продукт изменен");
          })
          .catch((error) => {
            reject(error);
            enqueueSnackbar("Произошла ошибка при изменении продукта");
          });
      });
    },
    []
  );
};
