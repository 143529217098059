import { Box, Paper, Typography } from "@material-ui/core";
import { useDeviceEfficiency } from "api";
import { Loader } from "components/Loader";
import { format } from "date-fns";
import { FC, useCallback, useState, useMemo, useEffect } from "react";
import { useParams } from "react-router";
import { useStoreon } from "storeon/react";
import { EfficiencyTable } from "./EfficiencyTable";

export const EfficiencyPage: FC = () => {
  const { daterange } = useStoreon("daterange");
  const params = useParams<{ deviceId: string }>();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const { device, dispatch } = useStoreon("device");

  const selectedDeviceId = useMemo(() => params.deviceId, [params.deviceId]);

  if (!device.selectedDevice) {
    dispatch("device/set", {
      id: "selectedDevice",
      value: selectedDeviceId,
    });
  }

  const { efficiencyData, efficiencyMutate, efficiencyUpdates } =
    useDeviceEfficiency({
      deviceId: selectedDeviceId,
      additionalParams: {
        pageNumber: pageNumber,
        pageSize: pageSize,
        from: format(daterange.from, "yyyy-LL-dd"),
        to: format(daterange.to, "yyyy-LL-dd"),
      },
    });

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
  }, []);

  const handlePageChange = useCallback((pageNumber: number) => {
    setPageNumber(pageNumber);
  }, []);

  useEffect(() => {
    efficiencyMutate();
  }, [pageNumber, pageSize]);

  if (!efficiencyData.efficiency) {
    return <Loader />;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Paper>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={3}
        >
          <Typography variant="h6">Сводная эффективность</Typography>
          <Typography>Всего: {efficiencyData.efficiency.itemsCount}</Typography>
        </Box>
        <EfficiencyTable
          data={efficiencyData.efficiency.eventsStatisticList}
          pageNumber={efficiencyData.efficiency.pageNumber}
          loading={efficiencyUpdates}
          totalNumberOfRows={efficiencyData.efficiency.itemsCount}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
        />
      </Paper>
    </Box>
  );
};
