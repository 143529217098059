import React, { FC, useMemo, CSSProperties } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { StatusProps } from "./types";

export const Status: FC<StatusProps> = (props) => {
  const style = useMemo<CSSProperties>(() => ({ pointerEvents: "none" }), []);
  return (
    <Box style={style}>
      {typeof props.value === "boolean" ? (
        <Button
          startIcon={
            props.value ? <CheckIcon color={"primary"} /> : <CloseIcon />
          }
          size={"small"}
        >
          {props.text}
        </Button>
      ) : (
        <Button
          startIcon={<CircularProgress color={"inherit"} size={18} />}
          size={"small"}
          disabled={true}
        >
          Ожидание
        </Button>
      )}
    </Box>
  );
};
