import { Box, Paper, Typography } from "@material-ui/core";
import {
  DataGrid,
  GridColumns,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import Tooltip from "@material-ui/core/Tooltip";
import { FC, useMemo, useCallback } from "react";
import { CookingsProps } from "./types";
import { useStyles } from "./styles";

export const Cookings: FC<CookingsProps> = ({ cookings }) => {
  const classes = useStyles();

  const indexedCookings = useMemo(() => {
    return cookings.map((cooking, id) => ({ ...cooking, id }));
  }, [cookings]);

  const renderValue = useCallback(
    ({ row, field }: GridValueFormatterParams) => {
      return (
        <Box display={"flex"} alignItems={"center"} width={"100%"}>
          {row[field]}
        </Box>
      );
    },
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "productName",
        headerName: "Напиток",
        width: 510,
        editable: false,
        renderCell: renderValue,
      },
      {
        field: "successful",
        headerName: "Проданные напитки",
        width: 465,
        editable: false,
        renderCell: renderValue,
      },
      {
        field: "testCooking",
        headerName: "Тестовые напитки",
        editable: false,
        renderCell: renderValue,
        width: 465,
      },
    ],
    []
  );

  return (
    <Paper className={classes.tableWrapper}>
      <Typography className={classes.tableHeader}>Статистика варок</Typography>
      <DataGrid
        columns={columns}
        rows={indexedCookings}
        disableSelectionOnClick={true}
        checkboxSelection={false}
        disableColumnResize={true}
        disableColumnMenu={true}
        autoHeight={true}
      />
    </Paper>
  );
};
