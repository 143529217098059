import { FC } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  Box,
  Divider,
} from "@material-ui/core";
import { NavigationDrawerProps, NavigationDrawerRoute } from "./types";
import { useHistory } from "react-router";
import { useStyles } from "./styles";
import { useVersion } from "api";
import { theme } from "theme";

export const NavigationDrawer: FC<NavigationDrawerProps> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const version = useVersion();

  const handleRouteClick = (route: string) => {
    history.replace(route);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box component="span" paddingY="30px" paddingLeft="28px">
        Меню
      </Box>
      <Divider />
      <List>
        {props.routes.map((route: NavigationDrawerRoute) => (
          <ListItem
            button
            key={route.routeName}
            onClick={() => handleRouteClick(route.route)}
            selected={history.location.pathname.includes(route.routeName)}
            classes={{
              selected: classes.selectedRoute,
            }}
          >
            <ListItemIcon>
              {route.icon ? (
                <Icon
                  color={
                    history.location.pathname.includes(route.routeName)
                      ? "primary"
                      : "action"
                  }
                >
                  {route.icon}
                </Icon>
              ) : null}
            </ListItemIcon>
            <ListItemText>{route.label}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Box flex="1"></Box>
      <Box alignSelf="center" pb={3} color={theme.palette.grey[400]}>
        {version?.data ? version?.data?.git?.commit?.id : ""}
      </Box>
    </Drawer>
  );
};
