import useSWR from "swr";
import { useStoreon } from "storeon/react";
import { DeviceEfficiencyParams, GetDeviceEfficiency } from "api";
import { useCallback, useMemo, useRef, useEffect } from "react";
import { isEqual } from "lodash";

export const useDeviceEfficiency = (props: DeviceEfficiencyParams) => {
  const { deviceId, additionalParams } = props;

  const url = useMemo(() => {
    let url = additionalParams
      ? `/api/device/${deviceId}/summary?${Object.keys(additionalParams)
          .map((key) => key + "=" + additionalParams[key])
          .join("&")}`
      : `/api/device/${deviceId}/summary`;
    return url;
  }, [deviceId, additionalParams]);

  const refresh = useRef(false);

  const { dispatch, efficiency } = useStoreon("efficiency");

  const { data, mutate } = useSWR<GetDeviceEfficiency>(url, { refreshInterval: +process.env.REFRESH_INTERVAL_MS });

  const mutateDevicesEfficiency = useCallback(() => {
    refresh.current = true;
    mutate();
  }, [data]);

  useEffect(() => {
    if (!deviceId || !data) return;
    if (
      refresh.current ||
      !isEqual(efficiency.efficiency, {
        efficiency: data,
      })
    ) {
      dispatch("efficiency/set", {
        efficiency: data,
      });
      refresh.current = false;
    }
  }, [data]);

  const updates = useMemo<boolean>(() => {
    if (!deviceId || !data || !efficiency.efficiency) return false;
    return isEqual(efficiency.efficiency, data);
  }, [deviceId, data, efficiency]);

  return {
    efficiencyData: efficiency,
    efficiencyMutate: mutateDevicesEfficiency,
    efficiencyUpdates: !updates,
  };
};
