import { Box, Typography } from "@material-ui/core";
import { FC } from "react";
import { useStyles } from "./styles";
import { DeviceStatuses, DeviceStatusProps } from "./types";

export const DeviceStatus: FC<DeviceStatusProps> = ({ status }) => {
  const classes = useStyles();

  const OfflineStatusIcon = () => (
    <Box className={`${classes.statusIcon} ${classes.offlineStatusIcon}`}></Box>
  );

  const OnlineStatusIcon = () => (
    <Box className={`${classes.statusIcon} ${classes.onlineStatusIcon}`}></Box>
  );

  if (status === DeviceStatuses.Online) {
    return (
      <Box display="flex" alignItems="center">
        <OnlineStatusIcon />
        <Typography className={classes.onlineText}>Online</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <OfflineStatusIcon />
      <Typography className={classes.offlineText}>Offline</Typography>
    </Box>
  );
};
