import { Box } from "@material-ui/core";
import {
  DataGrid,
  GridColumns,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import { parseISO, format } from "date-fns";
import { FC, useMemo, useCallback } from "react";
import { EfficiencyTableProps } from "./types";

export const EfficiencyTable: FC<EfficiencyTableProps> = ({
  data,
  pageNumber,
  loading,
  totalNumberOfRows,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const renderValue = useCallback(
    ({ row, field }: GridValueFormatterParams) => {
      return (
        <Box display={"flex"} alignItems={"center"} width={"100%"}>
          {row[field]}
        </Box>
      );
    },
    []
  );

  const renderTime = useCallback(({ row, field }: GridValueFormatterParams) => {
    return (
      <Box display={"flex"} alignItems={"center"} width={"100%"}>
        {format(parseISO(row[field]), "dd.MM.yyyy")}
      </Box>
    );
  }, []);

  const datagridRows = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        id: item.date,
      })),
    [data]
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "date",
        headerName: "Дата",
        width: 145,
        editable: false,
        renderCell: renderTime,
      },
      {
        field: "workTime",
        headerName: "Время работы",
        flex: 1,
        editable: false,
        renderCell: renderValue,
      },
      {
        field: "outOfWorkTime",
        headerName: "Время простоя",
        flex: 1,
        editable: false,
        renderCell: renderValue,
      },
      {
        field: "flushingCount",
        headerName: "Промывки",
        flex: 1,
        editable: false,
        renderCell: renderValue,
      },
      {
        field: "errorsCount",
        headerName: "Ошибки",
        flex: 1,
        editable: false,
        renderCell: renderValue,
      },
    ],
    []
  );

  return (
    <DataGrid
      columns={columns}
      rows={datagridRows}
      checkboxSelection={false}
      disableColumnResize={true}
      disableSelectionOnClick={true}
      hideFooter={false}
      disableColumnMenu={true}
      autoHeight={true}
      paginationMode="server"
      page={pageNumber}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      loading={loading}
      rowCount={totalNumberOfRows}
    />
  );
};
