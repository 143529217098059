import { useCallback } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";

export const useChangeDeviceBlocked = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback((id: number, value: boolean) => {
    enqueueSnackbar(
      value ? "Начинаю блокировку машины" : "Начинаю активацию машины"
    );

    return axios({
      url: `/api/devices/${id}/commands/${value ? "block" : "unblock"}`,
      method: "PUT",
    }).catch(() => {
      enqueueSnackbar(
        value ? "Ошибка при блокировке машины" : "Ошибка при активации машины"
      );
    });
  }, []);
};
