import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
      color: grey["50"],
      textDecoration: "none",
      display: "flex",
    },
    logoImg: {
      marginRight: "12px",
    },
  })
);
