export const efficiency = (store) => {
  store.on("@init", () => ({
    efficiency: {
      efficiency: null,
    },
  }));
  store.on("efficiency/set", (_, { efficiency }) => ({
    efficiency: {
      efficiency,
    },
  }));
};
