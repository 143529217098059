import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      border: "1px solid black",
      borderColor: theme.palette.primary.main,
      padding: "8px 33px",
      textAlign: "center",
    },
    activeTab: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
  })
);
