import useSWR from "swr";
import { useStoreon } from "storeon/react";
import { DevicesStatisticsParams, GetDevicesStatistics } from "api";
import { useCallback, useMemo, useRef, useEffect } from "react";
import { isEqual } from "lodash";

export const useDevicesStatistics = (props: DevicesStatisticsParams) => {
  const { deviceIds, from, to, pageNumber, pageSize } = props;

  const url = useMemo(() => {
    let url = props.deviceIds
      ? `/api/devices/stat?${Object.keys(props)
          .map((key) => key + "=" + props[key])
          .join("&")}`
      : `/api/devices/stat`;
    return url;
  }, [deviceIds, from, to, pageNumber, pageSize]);

  const refresh = useRef(false);

  const { dispatch, statistics } = useStoreon("statistics");

  const { data, mutate } = useSWR<GetDevicesStatistics>(url, { refreshInterval: +process.env.REFRESH_INTERVAL_MS });

  const mutateDevicesStatistics = useCallback(() => {
    refresh.current = true;
    mutate();
  }, [data]);

  useEffect(() => {
    if (!deviceIds || !data) return;
    if (refresh.current || !isEqual(statistics.statistics, data)) {
      dispatch("statistics/set", data);
      refresh.current = false;
    }
  }, [data]);

  const updates = useMemo<boolean>(() => {
    if (!deviceIds || !data || !statistics.statistics) return false;
    return isEqual(statistics.statistics, data);
  }, [deviceIds, data, statistics]);

  return {
    statisticsData: statistics,
    statisticsMutate: mutateDevicesStatistics,
    statisticsUpdates: !updates,
  };
};
