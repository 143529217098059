import {
  Box,
} from "@material-ui/core";
import { useDevice, useDevicesStatistics } from "api";
import { Loader } from "components/Loader";
import { format } from "date-fns";
import { FC, useMemo, useEffect } from "react";
import { useParams } from "react-router";
import { useStoreon } from "storeon/react";
import { DeviceDetailConsumption } from "./Consumption";
import { Cookings } from "./Cookings";
import { DeviceInfo } from "./DeviceInfo";
import { useStyles } from "./styles";

export const StatisticsPage: FC = () => {
  const classes = useStyles();
  const { device, dispatch } = useStoreon("device");

  const { daterange } = useStoreon("daterange");

  const params = useParams<{ deviceId: string }>();

  const { deviceData, deviceMutate } = useDevice(params.deviceId, {
    from: daterange.from,
    to: daterange.to,
  });
  const selectedDeviceId = useMemo(() => params.deviceId, [params.deviceId]);

  // for page reload handle
  if (!device.selectedDevice) {
    dispatch("device/set", {
      id: "selectedDevice",
      value: selectedDeviceId,
    });
  }

  const selectedDevice = useMemo(() => {
    if (deviceData) {
      return deviceData;
    }
    return null;
  }, [deviceData, selectedDeviceId]);

  const { statisticsData, statisticsMutate } = useDevicesStatistics({
    deviceIds: [selectedDeviceId],
    pageNumber: 0,
    pageSize: 10,
    from: format(daterange.from, "yyyy-LL-dd"),
    to: format(daterange.to, "yyyy-LL-dd"),
  });

  useEffect(() => {
    deviceMutate();
    statisticsMutate();
  }, [selectedDeviceId]);

  if (!deviceData) {
    return <Loader />;
  }

  return (
    <Box className={classes.wrapper}>
      <Box mb={3}>
        <DeviceInfo
          deviceData={selectedDevice}
          deviceStatistics={statisticsData?.deviceStatList?.find(
            (stat: { id: string | number }) => `${stat.id}` === selectedDeviceId
          )}
        />
      </Box>
      <DeviceDetailConsumption
        {...selectedDevice.consumption}
        mutate={deviceMutate}
        dateRange={{
          from: daterange.from,
          to: daterange.to,
        }}
      />
      <Cookings cookings={selectedDevice.cookings} />
    </Box>
  );
};
