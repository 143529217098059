import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const drawerWidth = 325;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      display: "flex",
      flexFlow: "column",
      width: drawerWidth,
      paddingTop: "64px",
    },
    selectedRoute: {
      color: theme.palette.primary.main,
    },
  })
);
