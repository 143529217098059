import { FC } from "react";
import { Link } from "react-router-dom";
import { AppBar, Typography, Toolbar, Container } from "@material-ui/core";

import { HeaderMenu } from "./Menu";
import { useStyles } from "./styles";

import Logo from "images/logo.svg";

export const Header: FC = (props) => {
  const classes = useStyles();

  return (
    <AppBar position={"sticky"} color={"secondary"} className={classes.appBar}>
      <Container maxWidth={"xl"} disableGutters={true}>
        <Toolbar>
          <Link to={"/"} className={classes.logo}>
            <img src={Logo} className={classes.logoImg} />
            <Typography variant={"h6"}>Мониторинг</Typography>
          </Link>
          <HeaderMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
