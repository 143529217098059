import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import isEqual from "lodash/isEqual";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { DeviceDetailConsumptionProps } from "./types";
import { parseISO } from "date-fns";

export const DeviceDetailConsumption: FC<DeviceDetailConsumptionProps> = (
  props
) => {
  const block = useRef<HTMLDivElement | null>(null);
  const chart = useRef<am4charts.XYChart | null>(null);
  const prevProps = useRef<null | object>(null);

  const [tab, setTab] = useState(0);

  const handleChangeTab = useCallback((_, value) => {
    setTab(value);
  }, []);

  const tabs = useMemo(
    () => [
      {
        label: "Кофе",
        id: "coffee",
      },
      {
        label: "Шоколад",
        id: "cocoa",
      },
      {
        label: "Молоко",
        id: "milk",
      },
      {
        label: "Сублимированный кофе",
        id: "coffee_sublimate",
      },
    ],
    []
  );

  const update = useCallback(() => {
    if (!block.current) return;

    prevProps.current = props;

    am4core.options.queue = true;
    am4core.options.onlyShowOnViewport = true;

    chart.current = am4core.create(block.current, am4charts.XYChart);

    chart.current.language.locale = am4lang_ru_RU;

    const { id } = tabs[tab];

    const data = props[id];

    if (!data || data.length === 0) return;

    chart.current.data = data.map((item) => {
      const timestamp = parseISO(item.timestamp);
      return {
        value: item.amount,
        timestamp_: item.timestamp,
        date: timestamp,
      };
    });

    const dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.renderer.minGridDistance = 45;

    chart.current.yAxes.push(new am4charts.ValueAxis());

    const series = chart.current.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.name = "Series #1";
    series.tooltipText = "{dateX}: [b]{valueY}[/]";
    series.strokeWidth = 2;
    series.showOnInit = false;

    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.xAxis = dateAxis;
    chart.current.cursor.snapToSeries = series;
    chart.current.cursor.snapToSeries = series;

    chart.current.events.on("ready", () => {
      dateAxis.zoomToDates(props.dateRange.from, props.dateRange.to);
    });
  }, [props, tabs, tab]);

  useEffect(() => {
    if (!isEqual(props, prevProps.current)) {
      update();
    }
  }, [props, tabs]);

  useEffect(() => {
    update();
  }, [tab]);

  return (
    <Box mt={3}>
      <Paper>
        <Box display={"flex"} justifyContent={"space-between"} py={2} px={4}>
          <Typography variant={"h6"}>Статистика расхода</Typography>
        </Box>
        <Tabs
          value={tab}
          indicatorColor={"primary"}
          textColor={"primary"}
          onChange={handleChangeTab}
        >
          {tabs.map((item) => (
            <Tab key={item.id} label={item.label} />
          ))}
        </Tabs>
        <Divider />
        <Box pt={1} pb={3} px={1}>
          <Box overflow={"hidden"}>
            <div ref={block} style={{ height: 400, marginBottom: -18 }} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
