import React from "react";
import ReactDOM from "react-dom";
import { StoreContext } from "storeon/react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider, SnackbarOrigin } from "notistack";
import { SWRConfig } from "swr";

import { App } from "components/App";
import { Auth } from "components/Auth";
import { store } from "store";

import { theme } from "./theme";

const anchorOrigin: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const swrConfig = { revalidateOnFocus: false };

ReactDOM.render(
  <SWRConfig value={swrConfig}>
    <StoreContext.Provider value={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <SnackbarProvider anchorOrigin={anchorOrigin}>
            <Router basename={process.env.PUBLIC_URL}>
              <Auth>
                <App />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreContext.Provider>
  </SWRConfig>,
  document.getElementById("root")
);
