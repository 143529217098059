import useSWR from "swr";
import { useStoreon } from "storeon/react";
import { DeviceErrorsParams, GetDeviceErrors } from "api";
import { useCallback, useMemo, useRef, useEffect } from "react";
import { isEqual } from "lodash";

export const useDeviceErrors = (props: DeviceErrorsParams) => {
  const { deviceId, additionalParams } = props;

  const url = useMemo(() => {
    let url = additionalParams
      ? `/api/device/${deviceId}/errors?${Object.keys(additionalParams)
          .map((key) => key + "=" + additionalParams[key])
          .join("&")}`
      : `/api/device/${deviceId}/errors`;
    return url;
  }, [deviceId, additionalParams]);

  const refresh = useRef(false);

  const { dispatch, errors } = useStoreon("errors");

  const { data, mutate } = useSWR<GetDeviceErrors>(url, { refreshInterval: +process.env.REFRESH_INTERVAL_MS });

  const mutateDevicesErrors = useCallback(() => {
    refresh.current = true;
    mutate();
  }, [data]);

  useEffect(() => {
    if (!deviceId || !data) return;
    if (
      refresh.current ||
      !isEqual(errors.errors, {
        errors: data,
      })
    ) {
      dispatch("errors/set", {
        errors: data,
      });
      refresh.current = false;
    }
  }, [data]);

  const updates = useMemo<boolean>(() => {
    if (!deviceId || !data || !errors.errors) return false;
    return isEqual(errors.errors, data);
  }, [deviceId, data, errors]);

  return {
    errorsData: errors,
    errorsMutate: mutateDevicesErrors,
    errorsUpdates: !updates,
  };
};
