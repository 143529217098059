import { Box, Typography } from "@material-ui/core";
import { FC } from "react";
import { DeviceStateProps, DeviceStates } from "./types";

import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { useStyles } from "./styles";

export const DeviceState: FC<DeviceStateProps> = ({ state }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      {!state ? (
        <CheckIcon className={classes.activeIcon} />
      ) : (
        <CloseIcon className={classes.disabledIcon} />
      )}
      <Typography className={classes.stateLabel}>
        {!state ? "Активирован" : "Не активирован"}
      </Typography>
    </Box>
  );
};
