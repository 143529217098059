import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexFlow: "column",
      padding: "20px",
    },
    characteristicName: {
      fontSize: "16px",
    },
    characteristicDescription: {
      color: theme.palette.secondary.light,
    },
    characteristicClickable: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  })
);
