import React, { FC, useCallback, useMemo, useState } from "react";
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColumns,
  GridSortModel,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";

import { RecipesPageProductsProps } from "./types";

export const RecipesPageProducts: FC<RecipesPageProductsProps> = (props) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "button", sort: "asc" },
    { field: "name", sort: "asc" },
  ]);

  const renderIndex = useCallback(
    ({ row, field }: GridValueFormatterParams) => {
      return row[field] || "";
    },
    []
  );

  const renderValue = useCallback(
    ({ row, field }: GridValueFormatterParams) => {
      return (
        <Tooltip title={"Нажмите дважды для редактирования"}>
          {row[field] ? (
            <Box display={"flex"} alignItems={"center"} width={"100%"}>
              <Box display={"flex"} alignItems={"center"} mr={1}>
                <EditIcon color={"disabled"} fontSize={"small"} />
              </Box>
              {row[field]}
            </Box>
          ) : (
            <Box width={"100%"}>&nbsp;</Box>
          )}
        </Tooltip>
      );
    },
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "button",
        headerName: "Кнопка",
        width: 125,
        editable: false,
        renderCell: renderIndex,
        type: "number",
      },
      {
        field: "name",
        headerName: "Наименование",
        width: 345,
        editable: true,
        renderCell: renderValue,
      },
      {
        field: "coffee",
        headerName: "Кофе",
        width: 165,
        editable: true,
        renderCell: renderValue,
      },
      {
        field: "cocoa",
        headerName: "Шоколад",
        width: 165,
        editable: true,
        renderCell: renderValue,
      },
      {
        field: "milk",
        headerName: "Молоко",
        width: 165,
        editable: true,
        renderCell: renderValue,
      },
      {
        field: "coffee_sublimate",
        headerName: "Сублимированный кофе",
        width: 265,
        editable: true,
        renderCell: renderValue,
      },
    ],
    []
  );

  const onSortModelChange = useCallback((model) => {
    setSortModel(model);
  }, []);

  const onCellEditCommit = useCallback(
    ({ id, field, value }: GridCellEditCommitParams) => {
      if (field !== "name") {
        value = typeof value === "string" ? parseInt(value) : 0;
      }
      const row = props.data.find((e) => e.id === id);
      if (!row || row[field] === value) return;
      props.handleChange(id, {
        ...row,
        [field]: value,
      });
    },
    [props.data]
  );

  return (
    <Box mt={3}>
      <Paper>
        <Box py={2} px={4}>
          <Typography variant={"h6"}>Рецепты</Typography>
        </Box>
        <Divider />
        <Box overflow={"hidden"}>
          <Box my={"-2px"}>
            <DataGrid
              columns={columns}
              rows={props.data}
              checkboxSelection={false}
              disableColumnResize={true}
              disableSelectionOnClick={true}
              hideFooter={true}
              disableColumnMenu={true}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              autoHeight={true}
              onCellEditCommit={onCellEditCommit}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
