import React, { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { LoginContext } from "api";

export const Auth: FC = (props) => {
  const history = useHistory();

  const [isAuth, setIsAuth] = useState<boolean>(
    !!localStorage.getItem("is-auth")
  );

  const handleLogin = useCallback((password) => {
    if (password === "idontknow") {
      setIsAuth(true);
      localStorage.setItem("is-auth", "1");
    }
  }, []);

  const handleLogout = useCallback(() => {
    setIsAuth(false);
    localStorage.removeItem("is-auth");
    history.push("/");
  }, []);

  const value = useMemo(
    () => ({
      isAuth,
      handleLogin,
      handleLogout,
    }),
    [isAuth, handleLogin, handleLogout]
  );

  return (
    <LoginContext.Provider value={value}>
      {props.children}
    </LoginContext.Provider>
  );
};
