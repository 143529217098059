import { useCallback, useEffect, useMemo, useRef } from "react";
import { useStoreon } from "storeon/react";
import isEqual from "lodash/isEqual";
import format from "date-fns/format";
import useSWR from "swr";

import { GetDevice, DateRange, RequestError } from "api";

export const useDevice = (id: number | string, dateRange?: DateRange) => {
  const url = useMemo(() => {
    let url = id ? `/api/device/${id}` : null;
    if (dateRange) {
      const from = format(dateRange.from, "yyyy-LL-dd");
      const to = format(dateRange.to, "yyyy-LL-dd");
      url += `?from=${from}&to=${to}`;
    }
    return url;
  }, [id, dateRange]);

  const refresh = useRef(false);

  const { dispatch, device } = useStoreon("device");

  const { data, mutate } = useSWR<GetDevice>(url, { refreshInterval: +process.env.REFRESH_INTERVAL_MS });

  const mutateDevice = useCallback(() => {
    refresh.current = true;
    mutate();
  }, [data]);

  useEffect(() => {
    if (!id || !data || id === "null") return;
    if (
      !device[id] ||
      data.device.status !== device[id].device.status ||
      data.device.isBlocked !== device[id].device.isBlocked ||
      refresh.current
    ) {
      dispatch("device/set", { id, value: data });
      refresh.current = false;
    }
  }, [id, data, device]);

  const updates = useMemo<boolean>(() => {
    if (!id || !data || !device[id]) return false;
    return isEqual(device[id], data);
  }, [id, data, device]);

  return {
    deviceData: device[id],
    deviceMutate: mutateDevice,
    deviceUpdates: !updates,
  };
};
