export const statistics = (store) => {
  store.on("@init", () => ({
    statistics: {
      statistics: null,
    },
  }));
  store.on("statistics/set", (_, statisticsData) => ({
    statistics: statisticsData,
  }));
};
