import { Box, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { useDeviceErrors, useDeviceEvents } from "api";
import { Loader } from "components/Loader";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useStoreon } from "storeon/react";
import { ErrorsTable } from "./Errors";
import { EventsTable } from "./Events";
import { useStyles } from "./styles";

const TAB_NAMES = ["Обслуживание", "Ошибки"];

export const MessagesPage: FC = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [errorsPageNumber, setErrorsPageNumber] = useState(0);
  const [eventsPageNumber, setEventsPageNumber] = useState(0);
  const [errorsPageSize, setErrorsPageSize] = useState(100);
  const [eventsPageSize, setEventsPageSize] = useState(100);
  const { device, dispatch } = useStoreon("device");
  const { daterange } = useStoreon("daterange");
  const params = useParams<{ deviceId: string }>();

  const selectedDeviceId = useMemo(() => params.deviceId, [params.deviceId]);

  if (!device.selectedDevice) {
    dispatch("device/set", {
      id: "selectedDevice",
      value: selectedDeviceId,
    });
  }

  const { eventsData, eventsMutate, eventsUpdates } = useDeviceEvents({
    deviceId: selectedDeviceId,
    additionalParams: {
      pageNumber: eventsPageNumber,
      pageSize: eventsPageSize,
      from: format(daterange.from, "yyyy-LL-dd"),
      to: format(daterange.to, "yyyy-LL-dd"),
    },
  });
  const { errorsData, errorsMutate, errorsUpdates } = useDeviceErrors({
    deviceId: selectedDeviceId,
    additionalParams: {
      pageNumber: errorsPageNumber,
      pageSize: errorsPageSize,
      from: format(daterange.from, "yyyy-LL-dd"),
      to: format(daterange.to, "yyyy-LL-dd"),
    },
  });

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleErrorsPageSizeChange = useCallback((pageSize: number) => {
    setErrorsPageSize(pageSize);
  }, []);

  const handleEventsPageSizeChange = useCallback((pageSize: number) => {
    setEventsPageSize(pageSize);
  }, []);

  const handleErrorsPageChange = useCallback((pageNumber: number) => {
    setErrorsPageNumber(pageNumber);
  }, []);

  const handleEventsPageChange = useCallback((pageNumber: number) => {
    setEventsPageNumber(pageNumber);
  }, []);

  useEffect(() => {
    errorsMutate();
  }, [errorsPageNumber, errorsPageSize]);

  useEffect(() => {
    eventsMutate();
  }, [eventsPageNumber, eventsPageSize]);

  const tableName = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return TAB_NAMES[0];
      case 1:
        return TAB_NAMES[1];
      default:
        return "";
    }
  }, [selectedTab]);

  const tableCounter = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return eventsData.events?.itemsCount || 0;
      case 1:
        return errorsData.errors?.itemsCount || 0;
      default:
        return 0;
    }
  }, [selectedTab, eventsData.events, errorsData.errors]);

  if (!eventsData.events || !errorsData.errors) {
    return <Loader />;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box className={classes.tabsWrapper} mb={4}>
        <Tabs
          indicatorColor={"primary"}
          textColor={"primary"}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab label={TAB_NAMES[0]} />
          <Tab label={TAB_NAMES[1]} />
        </Tabs>
      </Box>
      <Paper>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={3}
        >
          <Typography variant="h6">{tableName}</Typography>
          <Typography>Всего: {tableCounter}</Typography>
        </Box>
        {selectedTab === 0 ? (
          <EventsTable
            data={eventsData.events.eventsStatisticList}
            pageNumber={eventsData.events.pageNumber}
            loading={eventsUpdates}
            totalNumberOfRows={eventsData.events.itemsCount}
            handlePageChange={handleEventsPageChange}
            handlePageSizeChange={handleEventsPageSizeChange}
          />
        ) : (
          <ErrorsTable
            data={errorsData.errors.errors}
            pageNumber={errorsData.errors.pageNumber}
            loading={errorsUpdates}
            totalNumberOfRows={errorsData.errors.itemsCount}
            handlePageChange={handleErrorsPageChange}
            handlePageSizeChange={handleErrorsPageSizeChange}
          />
        )}
      </Paper>
    </Box>
  );
};
