import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import addDays from "date-fns/addDays";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { PopoverOrigin } from "@material-ui/core/Popover/Popover";
import DateRangeIcon from "@material-ui/icons/DateRange";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import isEqual from "lodash/isEqual";
import { KeyboardDatePicker } from "@material-ui/pickers";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { DeviceDetailConsumptionProps } from "./types";

import "./styles.css";

export const DeviceDetailConsumption: FC<DeviceDetailConsumptionProps> = (
  props
) => {
  const block = useRef<HTMLDivElement | null>(null);
  const chart = useRef<am4charts.XYChart | null>(null);
  const state = useRef<null | object>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [tab, setTab] = useState(0);

  const open = Boolean(anchorEl);

  const id = open ? "consumption-popover" : undefined;

  const anchorOrigin = useMemo<PopoverOrigin>(
    () => ({
      vertical: 40,
      horizontal: "right",
    }),
    []
  );

  const transformOrigin = useMemo<PopoverOrigin>(
    () => ({
      vertical: "top",
      horizontal: "right",
    }),
    []
  );

  const handleChangeTab = useCallback((_, value) => {
    setTab(value);
  }, []);

  const tabs = useMemo(
    () => [
      {
        label: "Кофе",
        id: "coffee",
      },
      {
        label: "Шоколад",
        id: "cocoa",
      },
      {
        label: "Молоко",
        id: "milk",
      },
      {
        label: "Сублимированный кофе",
        id: "coffee_sublimate",
      },
    ],
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const update = useCallback(() => {
    if (!block.current) return;

    state.current = props;

    am4core.options.queue = true;
    am4core.options.onlyShowOnViewport = true;

    chart.current = am4core.create(block.current, am4charts.XYChart);

    chart.current.language.locale = am4lang_ru_RU;

    const { id } = tabs[tab];

    const data = props[id];

    if (!data || data.length === 0) return;

    chart.current.data = data.map((item) => {
      const timestamp = item.timestamp.match(
        /^(\d{2})\.(\d{2})\.(\d{4})\s(\d{2}):(\d{2}):(\d{2})$/
      );
      return {
        value: item.amount,
        timestamp_: item.timestamp,
        date: new Date(
          parseInt(timestamp[3]),
          parseInt(timestamp[2]) - 1,
          parseInt(timestamp[1]),
          parseInt(timestamp[4]),
          parseInt(timestamp[5]),
          parseInt(timestamp[6])
        ),
      };
    });

    const dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.renderer.minGridDistance = 45;

    chart.current.yAxes.push(new am4charts.ValueAxis());

    const series = chart.current.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.name = "Series #1";
    series.tooltipText = "{dateX}: [b]{valueY}[/]";
    series.strokeWidth = 2;
    series.showOnInit = false;

    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.xAxis = dateAxis;
    chart.current.cursor.snapToSeries = series;
    chart.current.cursor.snapToSeries = series;

    chart.current.events.on("ready", () => {
      dateAxis.zoomToDates(props.dateRange.from, props.dateRange.to);
    });
  }, [props, tabs, tab]);

  const handleChangeStartDate = (date: Date | null) => {
    if (!isValid(date)) return;
    props.setDateRange({ ...props.dateRange, from: date });
    props.mutate();
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (!isValid(date)) return;
    props.setDateRange({ ...props.dateRange, to: date });
    props.mutate();
  };

  useEffect(() => {
    if (!isEqual(props, state.current)) {
      update();
    }
  }, [props, tabs]);

  useEffect(() => {
    update();
  }, [tab]);

  return (
    <Box mt={3}>
      <Paper>
        <Box display={"flex"} justifyContent={"space-between"} py={2} px={4}>
          <Typography variant={"h6"}>Статистика расхода</Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            disableScrollLock={true}
          >
            <Box p={2}>
              <Box>
                <KeyboardDatePicker
                  label={"Начало периода"}
                  format={"dd.MM.yyyy"}
                  value={props.dateRange.from}
                  onChange={handleChangeStartDate}
                />
              </Box>
              <Box mt={2}>
                <KeyboardDatePicker
                  label={"Конец периода"}
                  format={"dd.MM.yyyy"}
                  value={props.dateRange.to}
                  onChange={handleChangeEndDate}
                />
              </Box>
            </Box>
          </Popover>
          <Button onClick={handleClick} endIcon={<DateRangeIcon />}>
            {format(props.dateRange.from, "d.LL.yyyy")}
            &thinsp;&mdash;&thinsp;
            {format(props.dateRange.to, "d.LL.yyyy")}
          </Button>
        </Box>
        <Divider />
        <Tabs
          value={tab}
          indicatorColor={"primary"}
          textColor={"primary"}
          onChange={handleChangeTab}
        >
          {tabs.map((item) => (
            <Tab key={item.id} label={item.label} />
          ))}
        </Tabs>
        <Divider />
        <Box pt={1} pb={3} px={1}>
          <Box overflow={"hidden"}>
            <div ref={block} style={{ height: 400, marginBottom: -18 }} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
