import { FC, useMemo } from "react";
import { useParams } from "react-router";
import { useStoreon } from "storeon/react";
import { FormControl, Select, MenuItem, ListItemText } from "@material-ui/core";
import { useDevices } from "api";

export const DeviceSelect: FC = () => {
  const params = useParams<{ deviceId: string }>();
  const devices = useDevices({
    pageNumber: 0,
    pageSize: 100,
  });
  const { dispatch } = useStoreon("device");

  const selectedDeviceId = useMemo(() => params.deviceId, [params.deviceId]);

  const handleSelectedDeviceChange = (event) => {
    const deviceId = event.target.value;
    if (`${deviceId}` === selectedDeviceId) return;

    dispatch("device/set", {
      id: "selectedDevice",
      value: deviceId,
    });
  };

  return (
    <FormControl style={{ width: "400px" }}>
      <Select
        id="device-select"
        value={selectedDeviceId || ""}
        onChange={handleSelectedDeviceChange}
      >
        {devices?.data?.map((device) => {
          return (
            <MenuItem value={device.id} key={device.id}>
              <ListItemText
                primary={device.name}
                secondary={device.serialNumber}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
