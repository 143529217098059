import createTheme from "@material-ui/core/styles/createTheme";
import { ruRU } from "@material-ui/core/locale";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

export const theme = createTheme(
  {
    palette: {
      primary: {
        light: green[500],
        main: green[700],
        dark: green[900],
        contrastText: "#fff",
      },
      // will be replaced with new schema
      secondary: grey,
      // secondary: {
      //   main: "#f50057",
      //   light: "#ff4081",
      //   dark: "#c51162",
      //   contrastText: "#fff",
      // },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: "#fff",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
  },
  ruRU
);
