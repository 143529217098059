import { Paper, Box, Typography } from "@material-ui/core";
import { FC } from "react";
import { useStyles } from "./styles";
import { DeviceInfoProps, InfoItemProps } from "./types";
import { Loader } from "components/Loader";
import { DeviceStatus } from "components/DeviceStatus";
import { DeviceState } from "components/DeviceState";
import { deviceStateToString } from "helpers";

export const DeviceInfo: FC<DeviceInfoProps> = ({
  deviceData,
  deviceStatistics,
}) => {
  const classes = useStyles();

  const InfoItem: FC<InfoItemProps> = ({ name, value, handleValueClick }) => (
    <Box p={2} display="grid" gridTemplateRows="1fr 1fr">
      <Typography variant="h6" className={classes.characteristicName}>
        {name}
      </Typography>
      <Typography
        variant="caption"
        className={`${classes.characteristicDescription} ${
          handleValueClick && classes.characteristicClickable
        }`}
        onClick={handleValueClick ? handleValueClick : () => {}}
      >
        {value}
      </Typography>
    </Box>
  );

  if (!deviceData || !deviceStatistics)
    return (
      <Paper className={classes.wrapper}>
        <Loader />
      </Paper>
    );

  return (
    <Paper className={classes.wrapper}>
      {deviceData.device && (
        <>
          <Box display="flex">
            <Typography variant="h4">{deviceData.device.name}</Typography>
            <Box ml={2} display="flex" alignItems="center">
              <DeviceStatus status={deviceData.device.status} />
            </Box>
            <Box ml={2} display="flex" alignItems="center">
              <DeviceState state={deviceData.device.isBlocked} />
            </Box>
          </Box>
          <Box display="flex">
            <InfoItem name="Номер" value={deviceData.device.serialNumber} />
            <InfoItem name="Номер SIM" value={deviceData.device.simNumber} />
            <InfoItem name="Адрес" value={deviceData.device.address} />
            <InfoItem name="Модель" value={deviceData.device.model} />
            <InfoItem
              name="Текущее состояние"
              value={deviceStateToString(deviceData.device.state)}
            />
            <InfoItem
              name="Проданные напитки"
              value={`${deviceStatistics?.successful}`}
            />
            <InfoItem
              name="Тестовые варки"
              value={`${deviceStatistics?.testCooking}`}
            />
            {/* TODO: ERRORS AMOUNT */}
            <InfoItem name="Ошибки" value={`${deviceStatistics?.error}`} />
          </Box>
        </>
      )}
    </Paper>
  );
};
