export const deviceStateToString = (state: string) => {
  switch (state) {
    case "requires_service": {
      return "Требует обслуживания";
    }
    case "in_service_mode": {
      return "На обслуживании";
    }
    case "ready_for_work": {
      return "Готово к работе";
    }
    case "unknown": {
      return "Неизвестно";
    }
    default:
      return "Неизвестно";
  }
};
