import { FC } from "react";
import { Box } from "@material-ui/core";
import { FixedDaterangeTab } from "./FixedDaterangeTab";
import { SelectableDaterangeTab } from "./SelectableDaterangeTab";
import { Ranges } from "./types";
import { useStyles } from "./styles";
import { useStoreon } from "storeon/react";
import {
  endOfToday,
  endOfYesterday,
  isValid,
  startOfDay,
  startOfToday,
  startOfYesterday,
  subMonths,
  subWeeks,
} from "date-fns";

export const DaterangeSelector: FC = () => {
  const classes = useStyles();
  const { daterange, dispatch } = useStoreon("daterange");

  const handleDateChange = (values: {
    from?: Date;
    to?: Date;
    option?: Ranges;
  }) => {
    dispatch("daterange/set", values);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.fixedSelectorsWrapper}>
        <FixedDaterangeTab
          label="Вчера"
          isSelected={daterange.option === Ranges.Yesterday}
          onClick={() =>
            handleDateChange({
              from: startOfYesterday(),
              to: endOfYesterday(),
              option: Ranges.Yesterday,
            })
          }
        />
        <FixedDaterangeTab
          label="Сегодня"
          isSelected={daterange.option === Ranges.Today}
          onClick={() =>
            handleDateChange({
              from: startOfToday(),
              to: endOfToday(),
              option: Ranges.Today,
            })
          }
        />
        <FixedDaterangeTab
          label="Неделя"
          isSelected={daterange.option === Ranges.Week}
          onClick={() =>
            handleDateChange({
              from: subWeeks(endOfToday(), 1),
              to: endOfToday(),
              option: Ranges.Week,
            })
          }
        />
        <FixedDaterangeTab
          label="Месяц"
          isSelected={daterange.option === Ranges.Month}
          onClick={() =>
            handleDateChange({
              from: subMonths(endOfToday(), 1),
              to: endOfToday(),
              option: Ranges.Month,
            })
          }
        />
      </Box>
      <SelectableDaterangeTab
        dateRange={daterange}
        handleChangeEndDate={(date: Date) => {
          if (!isValid(date)) return;
          handleDateChange({
            to: startOfDay(date),
            option: Ranges.Custom,
          });
        }}
        handleChangeStartDate={(date: Date) => {
          if (!isValid(date)) return;
          handleDateChange({
            from: startOfDay(date),
            option: Ranges.Custom,
          });
        }}
        isSelected={daterange.option === Ranges.Custom}
      />
    </Box>
  );
};
