import { useCallback } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";

export const useEditDevice = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback((id: number | string, fields: object) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/device/${id}`,
        method: "PUT",
        data: fields,
      })
        .then((data) => {
          resolve(data);
          enqueueSnackbar("Девайс изменен");
        })
        .catch((error) => {
          reject(error);
          enqueueSnackbar("Произошла ошибка при изменении девайса");
        });
    });
  }, []);
};
