import React, { FC, useCallback, useMemo, useState } from "react";
import { addMonths, format } from "date-fns";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { KeyboardDatePicker } from "@material-ui/pickers";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import { PopoverOrigin } from "@material-ui/core/Popover/Popover";

import { DownloadProps } from "./types";

export const Download: FC<DownloadProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [startDate, setStartDate] = React.useState<Date | null>(
    addMonths(new Date(), -3)
  );
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());

  const handleChangeStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  const id = open ? "download-popover" : undefined;

  const anchorOrigin = useMemo<PopoverOrigin>(
    () => ({
      vertical: -8,
      horizontal: "right",
    }),
    []
  );

  const transformOrigin = useMemo<PopoverOrigin>(
    () => ({
      vertical: "bottom",
      horizontal: "right",
    }),
    []
  );

  const href = useMemo<string>(() => {
    const start = format(startDate, "yyyy-LL-dd");
    const end = format(endDate, "yyyy-LL-dd");
    return `/api/report/cup/${start}/${end}?deviceIds=${props.ids.join(",")}`;
  }, [startDate, endDate, props.ids]);

  return (
    <Box position={"fixed"} right={32} bottom={32}>
      <Fab color={"primary"} onClick={handleClick}>
        {!open ? <GetAppIcon /> : <CloseIcon />}
      </Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableScrollLock={true}
      >
        <Box pt={1} px={2} pb={2}>
          <Box mt={1}>
            <KeyboardDatePicker
              label="Начало периода"
              format="dd.MM.yyyy"
              value={startDate}
              onChange={handleChangeStartDate}
            />
          </Box>
          <Box mt={1}>
            <KeyboardDatePicker
              label="Конец периода"
              format="dd.MM.yyyy"
              value={endDate}
              onChange={handleChangeEndDate}
            />
          </Box>
          <Box mt={2}>
            <Button
              component={"a"}
              href={href}
              size={"small"}
              variant={"contained"}
              color={"primary"}
              download={true}
            >
              Скачать
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
