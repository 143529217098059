import useSWR from "swr";

import { GetDevices, DevicesStatusParams } from "api";

export const useDevices = (props: DevicesStatusParams) => {
  const { pageNumber, pageSize } = props;
  return useSWR<GetDevices>(
    `/api/devices/status/?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    { refreshInterval: +process.env.REFRESH_INTERVAL_MS }
  );
};
