import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      borderRight: "1px solid",
      borderColor: theme.palette.primary.main,
      padding: "8px 33px",
      minWidth: "132px",
      cursor: "pointer",
      "&:last-child": {
        borderRight: "none",
      },
    },
    activeTab: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
  })
);
