import { createStoreon } from "storeon";
import { storeonDevtools } from "storeon/devtools";
import { daterange } from "./daterange";

import { device } from "./device";
import { efficiency } from "./efficiency";
import { errors } from "./errors";
import { events } from "./events";
import { statistics } from "./statistics";

export const store = createStoreon([
  device,
  statistics,
  daterange,
  errors,
  events,
  efficiency,
  process.env.NODE_ENV !== "production" && storeonDevtools,
]);
