import React, { FC, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import addDays from "date-fns/addDays";

import { Loader } from "components/Loader";
import { Download } from "components/Download";
import { DeviceEdit } from "components/DeviceEdit";
import {
  useChangeDeviceBlocked,
  useEditProduct,
  useDevice,
  DateRange,
} from "api";

import { DeviceDetailInfo } from "./Info";
import { DeviceDetailConsumption } from "./Consumption";
import { DevicePageProducts } from "./Products";

export const DevicePage: FC = () => {
  const { id }: { id: string } = useParams();

  const [dateRange, setDateRange] = useState<DateRange>({
    from: addDays(Date.now(), -7),
    to: Date.now(),
  });

  const { deviceData, deviceMutate, deviceUpdates } = useDevice(id, dateRange);

  const changeDeviceBlocked = useChangeDeviceBlocked();
  const editProduct = useEditProduct();

  const [edit, setEdit] = useState<boolean>(false);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleBlock = useCallback((id) => {
    changeDeviceBlocked(id, true).then(() => {
      deviceMutate();
    });
  }, []);

  const handleActivate = useCallback((id) => {
    changeDeviceBlocked(id, false).then(() => {
      deviceMutate();
    });
  }, []);

  const handleClose = useCallback(() => {
    setEdit(false);
  }, []);

  const handleSubmit = useCallback(() => {
    handleClose();
    deviceMutate();
  }, []);

  const handleProductChange = useCallback((productId, value) => {
    editProduct(id, productId, value).then(() => {
      deviceMutate();
    });
  }, []);

  const ids = useMemo(() => [id], [id]);

  if (!deviceData) return <Loader />;

  return (
    <>
      {deviceData.device && (
        <DeviceDetailInfo
          id={id}
          updates={deviceUpdates}
          mutate={deviceMutate}
          handleEdit={handleEdit}
          handleBlock={handleBlock}
          handleActivate={handleActivate}
          {...deviceData.device}
        />
      )}
      {deviceData.consumption && (
        <DeviceDetailConsumption
          dateRange={dateRange}
          setDateRange={setDateRange}
          mutate={deviceMutate}
          {...deviceData.consumption}
        />
      )}
      {deviceData.products && (
        <DevicePageProducts
          data={deviceData.products}
          handleChange={handleProductChange}
        />
      )}
      <DeviceEdit
        id={id}
        open={edit}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <Download ids={ids} />
    </>
  );
};
