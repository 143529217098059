export const errors = (store) => {
  store.on("@init", () => ({
    errors: {
      errors: null,
    },
  }));
  store.on("errors/set", (_, { errors }) => ({
    errors: {
      errors,
    },
  }));
};
