import React, { FC } from "react";
import { useLocation, Link as LinkRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export const Back: FC = () => {
  const location = useLocation();
  if (location.pathname === "/") return null;
  return (
    <Box position={"fixed"} left={32} top={96}>
      <Fab color={"secondary"} size={"medium"} component={LinkRouter} to={"/"}>
        <ArrowBackIcon />
      </Fab>
    </Box>
  );
};
