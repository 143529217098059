import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useStoreon } from "storeon/react";
import { Link as LinkRouter, useLocation } from "react-router-dom";
import { DataGrid, GridColumns } from "@material-ui/data-grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { Box } from "@material-ui/core";

import { Loader } from "components/Loader";
import { Download } from "components/Download";
import { ActionMenu } from "components/ActionMenu";
import { DeviceEdit } from "components/DeviceEdit";
import { useDevices, useChangeDeviceBlocked } from "api";
import { useStyles } from "./styles";
import { DeviceStatus } from "components/DeviceStatus";
import { DeviceState } from "components/DeviceState";
import { deviceStateToString } from "helpers";

export const ListPage: FC = () => {
  const changeDeviceBlocked = useChangeDeviceBlocked();
  const classes = useStyles();

  const { dispatch } = useStoreon("device");

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const { search }: { search: string } = useLocation();
  const { data, mutate } = useDevices({
    pageNumber,
    pageSize,
  });

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
  }, []);

  const handlePageChange = useCallback((pageNumber: number) => {
    setPageNumber(pageNumber);
  }, []);

  useEffect(() => {
    mutate();
  }, [pageSize, pageNumber]);

  const [selected, setSelected] = useState<number[]>([]);
  const [edit, setEdit] = useState<number | null>(null);

  const handleEdit = useCallback((id) => {
    setEdit(id);
  }, []);

  const handleBlock = useCallback((id) => {
    changeDeviceBlocked(id, true).then(() => {
      mutate();
    });
  }, []);

  const handleActivate = useCallback((id) => {
    changeDeviceBlocked(id, false).then(() => {
      mutate();
    });
  }, []);

  const onSelectionModelChange = useCallback((ids) => {
    setSelected(ids);
  }, []);

  const handleClose = useCallback(() => {
    setEdit(null);
  }, []);

  const handleSubmit = useCallback(() => {
    setEdit(null);
    mutate();
  }, []);

  const filterModel = useMemo(() => {
    if (!search) return null;
    const params = new URLSearchParams(search);
    const query = params.get("search")?.toLowerCase();
    return {
      items: [
        {
          id: "name",
          columnField: "name",
          operatorValue: "contains",
          value: query,
        },
      ],
    };
  }, [search]);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "name",
        headerName: "Машина",
        width: 350,
        renderCell: (item) => {
          const link = `/statistics/${item.row?.id}`;
          return (
            <Link
              onClick={() => {
                dispatch("device/set", {
                  id: "selectedDevice",
                  value: item.row?.id,
                });
              }}
              to={link}
              component={LinkRouter}
            >
              {item.row?.name}
            </Link>
          );
        },
      },
      {
        field: "isBlocked",
        headerName: "Режим",
        width: 200,
        renderCell: (item) => {
          return <DeviceState state={item.row?.isBlocked} />;
        },
      },
      {
        field: "status",
        headerName: "Статус",
        width: 125,
        renderCell: (item) => {
          return <DeviceStatus status={item.row?.status} />;
        },
      },
      {
        field: "serialNumber",
        headerName: "Номер",
        width: 435,
      },
      {
        field: "state",
        headerName: "Текущее состояние",
        width: 205,
        renderCell: (item) => {
          return (
            <Box component="span">{deviceStateToString(item.row?.state)}</Box>
          );
        },
      },
      {
        field: "message",
        headerName: "События",
        width: 245,
        renderCell: (item) => {
          const message = item.row?.message?.caption;
          return <Box component="span">{message || "Нет"}</Box>;
        },
      },
      {
        field: "id",
        headerName: " ",
        width: 70,
        sortable: false,
        renderCell: (item) => {
          return (
            <ActionMenu
              id={item.row?.id}
              handleEdit={handleEdit}
              handleActivate={handleActivate}
              handleBlock={handleBlock}
            />
          );
        },
      },
    ],
    []
  );

  if (!data) return <Loader />;

  return (
    <Paper className={classes.paper}>
      <DataGrid
        columns={columns}
        rows={data}
        filterModel={filterModel}
        disableColumnResize={true}
        hideFooter={false}
        disableColumnMenu={true}
        checkboxSelection={true}
        disableSelectionOnClick={true}
        disableColumnFilter={true}
        autoHeight={true}
        onSelectionModelChange={onSelectionModelChange}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
      {selected.length > 0 && <Download ids={selected} />}
      {!!edit && (
        <DeviceEdit
          open={true}
          id={edit}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Paper>
  );
};
