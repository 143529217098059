import { Button, Box, Popover } from "@material-ui/core";
import { format } from "date-fns";
import { FC, useCallback, useState, useMemo } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { PopoverOrigin } from "@material-ui/core/Popover/Popover";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { useStyles } from "./styles";
import { SelectableDaterangeTabProps } from "./types";

export const SelectableDaterangeTab: FC<SelectableDaterangeTabProps> = ({
  dateRange,
  handleChangeStartDate,
  handleChangeEndDate,
  isSelected = false,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const anchorOrigin = useMemo<PopoverOrigin>(
    () => ({
      vertical: 40,
      horizontal: "right",
    }),
    []
  );

  const transformOrigin = useMemo<PopoverOrigin>(
    () => ({
      vertical: "top",
      horizontal: "right",
    }),
    []
  );

  return (
    <>
      <Button
        onClick={handleClick}
        className={`${classes.tab} ${isSelected && classes.activeTab}`}
        endIcon={<DateRangeIcon />}
      >
        {format(dateRange.from, "d.LL.yyyy")} &thinsp;&mdash;&thinsp;{" "}
        {format(dateRange.to, "d.LL.yyyy")}{" "}
      </Button>
      <Popover
        id={"date_popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableScrollLock={true}
      >
        <Box p={2}>
          <Box>
            <KeyboardDatePicker
              label={"Начало периода"}
              format={"dd.MM.yyyy"}
              value={dateRange.from}
              onChange={handleChangeStartDate}
            />
          </Box>
          <Box mt={2}>
            <KeyboardDatePicker
              label={"Конец периода"}
              format={"dd.MM.yyyy"}
              value={dateRange.to}
              onChange={handleChangeEndDate}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};
