import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabledIcon: {
      color: theme.palette.text.disabled,
    },
    activeIcon: {
      color: theme.palette.primary.main,
    },
    stateLabel: {
      marginLeft: "12px",
    },
  })
);
