export const events = (store) => {
  store.on("@init", () => ({
    events: {
      events: null,
    },
  }));
  store.on("events/set", (_, { events }) => ({
    events: {
      events,
    },
  }));
};
