import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexFlow: "column",
      flex: 1,
    },
  })
);
