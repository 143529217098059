import {
  TableCell,
  withStyles,
  Theme,
  makeStyles,
  createStyles,
} from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
    },
  })
);

export const TableCellSticky = withStyles((theme: Theme) => ({
  root: {
    top: theme.spacing(8),
  },
}))(TableCell);
