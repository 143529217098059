import useSWR from "swr";
import { useStoreon } from "storeon/react";
import { DeviceEventsParams, GetDeviceEvents } from "api";
import { useCallback, useMemo, useRef, useEffect } from "react";
import { isEqual } from "lodash";

export const useDeviceEvents = (props: DeviceEventsParams) => {
  const { deviceId, additionalParams } = props;

  const url = useMemo(() => {
    let url = additionalParams
      ? `/api/device/${deviceId}/events?${Object.keys(additionalParams)
          .map((key) => key + "=" + additionalParams[key])
          .join("&")}`
      : `/api/device/${deviceId}/events`;
    return url;
  }, [deviceId, additionalParams]);

  const refresh = useRef(false);

  const { dispatch, events } = useStoreon("events");

  const { data, mutate } = useSWR<GetDeviceEvents>(url, { refreshInterval: +process.env.REFRESH_INTERVAL_MS });

  const mutateDeviceEvents = useCallback(() => {
    refresh.current = true;
    mutate();
  }, [data]);

  useEffect(() => {
    if (!deviceId || !data) return;
    if (
      refresh.current ||
      !isEqual(events.events, {
        events: data,
      })
    ) {
      dispatch("events/set", {
        events: data,
      });
      refresh.current = false;
    }
  }, [data]);

  const updates = useMemo<boolean>(() => {
    if (!deviceId || !data || !events.events) return false;
    return isEqual(events.events, data);
  }, [deviceId, data, events]);

  return {
    eventsData: events,
    eventsMutate: mutateDeviceEvents,
    eventsUpdates: !updates,
  };
};
