import React, { FC, useContext } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { LoginContext } from "api";

export const HeaderMenu: FC = () => {
  const login = useContext(LoginContext);
  return (
    <Box ml={"auto"}>
      <IconButton color={"inherit"} onClick={login.handleLogout}>
        <ExitToAppIcon />
      </IconButton>
    </Box>
  );
};
