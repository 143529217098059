import { Ranges } from "components/DaterangeSelector/types";
import {
  endOfToday,
  endOfYesterday,
  parseJSON,
  startOfToday,
  startOfYesterday,
  subMonths,
  subWeeks,
} from "date-fns";

const getCachedDate = (date) => {
  try {
    const returnDate = parseJSON(date);

    if (returnDate.toString() !== "Invalid Date") {
      return parseJSON(date);
    }
    return new Date();
  } catch (e) {
    console.error(e);
    console.error("Something's wrong with date parsing");
    return new Date();
  }
};

export const daterange = (store) => {
  store.on("@init", () => {
    const cachedFrom = localStorage.getItem("daterange-from");
    const cachedTo = localStorage.getItem("daterange-to");
    let option = localStorage.getItem("daterange-option");
    let from = startOfToday();
    let to = endOfToday();
    if (option) {
      option = Ranges[option];

      switch (option) {
        case Ranges.Yesterday: {
          from = startOfYesterday();
          to = endOfYesterday();
          break;
        }
        case Ranges.Today: {
          from = startOfToday();
          to = endOfToday();
          break;
        }
        case Ranges.Week: {
          from = subWeeks(endOfToday(), 1);
          to = endOfToday();
          break;
        }
        case Ranges.Month: {
          from = subMonths(endOfToday(), 1);
          to = endOfToday();
          break;
        }
        case Ranges.Custom: {
          from = getCachedDate(cachedFrom);
          to = getCachedDate(cachedTo);
          break;
        }
        default:
          break;
      }
    } else {
      option = Ranges.Today;
    }
    return {
      daterange: {
        from,
        to,
        option,
      },
    };
  });
  store.on(
    "daterange/set",
    (
      { daterange },
      { from, to, option }: { from: Date; to: Date; option: Ranges }
    ) => {
      const result = {
        daterange: {
          ...daterange,
        },
      };
      if (from) {
        localStorage.setItem("daterange-from", JSON.stringify(from));
        result.daterange.from = from;
      }
      if (to) {
        localStorage.setItem("daterange-to", JSON.stringify(to));
        result.daterange.to = to;
      }
      if (option) {
        localStorage.setItem("daterange-option", option);
        result.daterange.option = option;
      }
      return result;
    }
  );
};
