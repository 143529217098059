import React, { FC } from "react";
import { Box, CircularProgress } from "@material-ui/core";

export const Loader: FC = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"calc(100vh - 128px)"}
    >
      <CircularProgress />
    </Box>
  );
};
