export const device = (store) => {
  store.on("@init", () => ({ device: {
    selectedDevice: null,
  } }));
  store.on("device/set", ({ device }, { id, value }) => ({
    device: {
      ...device,
      [id]: value,
    },
  }));
};