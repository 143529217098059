import React, { FC, useCallback, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useDevice, useEditDevice } from "api";

import { DeviceEditProps } from "./types";

export const DeviceEdit: FC<DeviceEditProps> = (props) => {
  const { deviceData } = useDevice(props.id);

  const editDevice = useEditDevice();

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [fields, setFields] = useState<{
    name: string;
    address: string;
    model: string;
  }>({
    name: "",
    address: "",
    model: "",
  });

  const handleChange = useCallback(
    (event) => {
      setFields({
        ...fields,
        [event.target.name]: event.target.value,
      });
    },
    [fields]
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setSubmitted(true);
      editDevice(props.id, fields)
        .then(() => {
          setSubmitted(false);
          props.handleSubmit();
        })
        .catch(() => {
          setSubmitted(false);
        });
    },
    [props.id, fields]
  );

  useEffect(() => {
    if (!deviceData) return null;
    setFields({
      name: deviceData.device.name || "",
      address: deviceData.device.address || "",
      model: deviceData.device.model || "",
    });
  }, [deviceData]);

  if (!deviceData) return null;

  return (
    <Dialog open={props.open}>
      <Box width={400} pt={4} pl={4} pr={4} pb={4} position={"relative"}>
        <form noValidate autoComplete={"off"} onSubmit={handleSubmit}>
          <Box mb={4}>
            <Typography variant={"h5"}>Редактирование</Typography>
          </Box>
          <Box>
            <TextField
              label={"Название"}
              variant={"outlined"}
              size={"small"}
              fullWidth={true}
              value={fields.name}
              name={"name"}
              onChange={handleChange}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label={"Адрес"}
              variant={"outlined"}
              size={"small"}
              fullWidth={true}
              value={fields.address}
              name={"address"}
              onChange={handleChange}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label={"Модель"}
              variant={"outlined"}
              size={"small"}
              fullWidth={true}
              value={fields.model}
              name={"model"}
              onChange={handleChange}
            />
          </Box>
          <Box
            mt={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Box mr={2}>
              <Button color={"secondary"} onClick={props.handleClose}>
                Отменить
              </Button>
            </Box>
            <Button
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              disabled={submitted}
            >
              Сохранить
            </Button>
          </Box>
        </form>
        <Box position={"absolute"} right={".5rem"} top={".5rem"}>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};
