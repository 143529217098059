import { Box } from "@material-ui/core";
import { FC } from "react";
import { useStyles } from "./styles";
import { FixedDaterangeTabProps } from "./types";

export const FixedDaterangeTab: FC<FixedDaterangeTabProps> = ({
  label,
  onClick,
  isSelected = false,
}) => {
  const classes = useStyles();

  return (
    <Box
      onClick={onClick}
      className={`${classes.tab} ${isSelected && classes.activeTab}`}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {label}
    </Box>
  );
};
