import React, { FC, useMemo } from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import SyncIcon from "@material-ui/icons/Sync";

import { ActionMenu } from "components/ActionMenu";
import { Status } from "components/Status";

import { DeviceDetailInfoProps } from "./types";

export const DeviceDetailInfo: FC<DeviceDetailInfoProps> = (props) => {
  const info = useMemo(() => {
    const data = [];
    data.push({ label: "Номер", value: props.serialNumber });
    data.push({ label: "Номер SIM", value: props.simNumber });
    data.push({ label: "Адрес", value: props.address });
    data.push({ label: "Модель", value: props.model });
    return data;
  }, [props]);

  const active = useMemo<boolean | null>(() => {
    return typeof props.isBlocked === "boolean" ? !props.isBlocked : null;
  }, [props.isBlocked]);

  const status = useMemo<boolean>(() => {
    return props.status === "ONLINE";
  }, [props.isBlocked]);

  return (
    <Paper>
      <Box py={3} px={4}>
        <Box display={"flex"} alignItems={"flex-end"}>
          <Typography variant={"h4"}>{props.name}</Typography>
          <Box ml={3}>
            <Status value={status} text={status ? "Online" : "Offline"} />
          </Box>
          <Box ml={2}>
            <Status
              value={active}
              text={active ? "Активирован" : "Заблокирован"}
            />
          </Box>
          <Box ml={"auto"}>
            <Tooltip title={"Обновить данные"}>
              <IconButton onClick={props.mutate}>
                <Badge
                  color={"primary"}
                  variant={"dot"}
                  invisible={!props.updates}
                >
                  <SyncIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <ActionMenu
              id={props.id}
              handleEdit={props.handleEdit}
              handleActivate={props.handleActivate}
              handleBlock={props.handleBlock}
            />
          </Box>
        </Box>
        <Box display={"flex"} mt={3}>
          {info.map((item, index) => {
            return (
              <Box key={item.label} ml={index > 0 ? 4 : 0}>
                <ListItemText
                  primary={item.label}
                  secondary={item.value || "отсутствует"}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Paper>
  );
};
