import { Box } from "@material-ui/core";
import {
  DataGrid,
  GridColumns,
  GridRowParams,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import { parseISO, format } from "date-fns";
import { FC, useMemo, useCallback } from "react";
import { getExecutionTime } from "./helpers";
import { useStyles } from "./styles";
import { ErrorsProps } from "./types";

export const ErrorsTable: FC<ErrorsProps> = ({
  data,
  pageNumber,
  loading,
  totalNumberOfRows,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const classes = useStyles();

  const renderValue = useCallback(
    ({ row, field }: GridValueFormatterParams) => {
      return (
        <Box display={"flex"} alignItems={"center"} width={"100%"}>
          {row[field]}
        </Box>
      );
    },
    []
  );

  const renderTime = useCallback(({ row, field }: GridValueFormatterParams) => {
    return (
      <Box display={"flex"} alignItems={"center"} width={"100%"}>
        {format(parseISO(row[field]), "dd.MM.yyyy hh:mm")}
      </Box>
    );
  }, []);

  const renderCompletionTime = useCallback(
    ({ row, field }: GridValueFormatterParams) => {
      return (
        <Box display={"flex"} alignItems={"center"} width={"100%"}>
          {row[field]
            ? getExecutionTime(row[field], row["startTimestamp"])
            : "Не решена"}
        </Box>
      );
    },
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "id",
        headerName: "id события",
        width: 145,
        editable: false,
        renderCell: renderValue,
      },
      {
        field: "errorName",
        headerName: "Событие",
        width: 350,
        editable: false,
        renderCell: renderValue,
      },
      {
        field: "startTimestamp",
        headerName: "Время поступления",
        flex: 1,
        editable: false,
        renderCell: renderTime,
      },
      {
        field: "endTimestamp",
        headerName: "Длительность решения",
        flex: 1,
        editable: false,
        renderCell: renderCompletionTime,
      },
    ],
    []
  );

  const getRowClassname = useCallback((params: GridRowParams) => {
    if (!params.row.endTimestamp) {
      return classes.unresolvedError;
    }
    return "";
  }, []);

  return (
    <DataGrid
      columns={columns}
      rows={data}
      checkboxSelection={false}
      disableColumnResize={true}
      disableSelectionOnClick={true}
      hideFooter={false}
      disableColumnMenu={true}
      autoHeight={true}
      paginationMode="server"
      page={pageNumber}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      loading={loading}
      rowCount={totalNumberOfRows}
      getRowClassName={getRowClassname}
    />
  );
};
