import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      maxHeight: "42px",
    },
    fixedSelectorsWrapper: {
      display: "flex",
      marginRight: "40px",
      borderWidth: "1px",
      borderRadius: "4px",
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
    },
  })
);
