import React, { FC, useCallback } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { ActionMenuProps } from "./types";

export const ActionMenu: FC<ActionMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = useCallback(() => {
    props.handleEdit(props.id);
    handleClose();
  }, [props.id]);

  const handleActivate = useCallback(() => {
    props.handleActivate(props.id);
    handleClose();
  }, [props.id]);

  const handleBlock = useCallback(() => {
    props.handleBlock(props.id);
    handleClose();
  }, [props.id]);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Редактировать</MenuItem>
        <MenuItem onClick={handleActivate}>Активировать</MenuItem>
        <MenuItem onClick={handleBlock}>Заблокировать</MenuItem>
      </Menu>
    </>
  );
};
