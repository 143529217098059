import React, { FC, useCallback, useContext, useState } from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { LoginContext } from "api";

export const AuthPage: FC = () => {
  const login = useContext(LoginContext);
  const [value, setValue] = useState<string>("");
  const handleChangeValue = useCallback((event) => {
    setValue(event.target.value);
  }, []);
  const handleSubmit = useCallback(() => {
    login.handleLogin(value);
  }, [value, login]);
  return (
    <Container maxWidth={"xs"}>
      <Paper>
        <Box p={4}>
          <Typography variant={"h5"}>Авторизация</Typography>
          <Box mt={3}>
            <TextField
              label={"Код-пароль"}
              variant={"outlined"}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth={true}
              value={value}
              onChange={handleChangeValue}
              autoFocus={true}
              type={"password"}
            />
          </Box>
          <Box mt={3}>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={handleSubmit}
            >
              Войти
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};
