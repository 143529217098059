import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      display: "flex",
      flexFlow: "column",
      backgroundColor: "white",
    },
    tableHeader: {
      fontSize: "20px",
      fontWeight: 500,
      margin: "18px 16px",
    },
  })
);
