import { differenceInSeconds, differenceInHours, parseISO } from "date-fns";

export const getExecutionTime = (
  endDateString: string,
  startDateString: string
) => {
  const firstDate = parseISO(endDateString);
  const secondDate = parseISO(startDateString);

  let seconds = differenceInSeconds(firstDate, secondDate);

  const hours = differenceInHours(firstDate, secondDate);

  seconds = seconds - hours * 3600;

  const minutes = Math.floor(seconds / 60);
  seconds = seconds - minutes * 60;

  return `${hours ? `${hours} ч` : ""} ${minutes ? `${minutes} мин` : ""} ${
    seconds ? `${seconds} с` : ""
  }`;
};
